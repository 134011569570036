import React from "react";

import './MobileLayout.less';

const  MobileIndexStore = (props) => {
  return <div className="m-index-store" style={props.style}>
    <div className="label">Download our app</div>
    <div>
      <a href="https://play.google.com/store/apps/details?id=tech.genx.teamlink" target="_blank">
        <img width="135" height="40" style={{ marginRight: 20 }} src="https://www.teamlink.com.au/wp-content/uploads/2021/10/Group-240.svg" className="attachment-large size-large" alt="" loading="lazy"></img>
      </a>
      <a href="https://apps.apple.com/us/app/teamlink-com-au/id1495499958?ls=1" target="_blank">
        <img width="119" height="40" src="https://www.teamlink.com.au/wp-content/uploads/2021/10/Download_App_Store_Badge_US.svg" className="attachment-large size-large" alt="" loading="lazy"></img>
      </a>
    </div>
    <img src={require('@src/images/mobile/mockup-image.png')} className="image" draggable={false}/>
  </div>
};

export default MobileIndexStore;