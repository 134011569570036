import React, { useState } from "react";
import {inject,observer} from 'mobx-react';
import compose from 'recompose/compose';
import {Input} from 'antd';

import SearchBarTwo from './SearchBarTwo';
import MobileFilter from './MobileFilter';
import './MobileLayout.less';

const { Search } = Input;

const MobileIndexSearch = ({ filterStore, propertyStore }) => {
  const {setShowMobileFilter, setCurrentFilterType} = filterStore;
  const {getMapProperties_  } = propertyStore
  const [type, setType] = useState('buyNew');

  const onSearch = () => {
    setCurrentFilterType(type);
    getMapProperties_();
  }

  return (
    <div className="m-index-search">
      <div className="tip">Find your dream home.</div>
      <div className="filter-tabs">
        <div className={`tab ${type==='buyNew'?"active":null}`} onClick={() => setType('buyNew')}>Buy New</div>
        <div className={`tab ${type==='buyExisting'?"active":null}`} onClick={() => setType('buyExisting')}>Buy Existing</div>
        <div className={`tab ${type==='rent'?"active":null}`} onClick={() => setType('rent')}>Rent</div>
      </div>
      <SearchBarTwo onSearch={onSearch} ifFilterButton={true} filterButtonClick={() => setShowMobileFilter(true)}/>
      <MobileFilter />
    </div>
  )
}

export default compose(inject('appStore','propertyStore', 'filterStore'), observer)(MobileIndexSearch)
