import React, { Suspense, useState, useLayoutEffect ,useRef ,useEffect} from "react";
import Typography from '@material-ui/core/Typography';
import { isMobile } from "react-device-detect";
import {compose} from 'recompose'
import _ from 'lodash'

import Container from "../components/container"
import Image from "../components/image"
import SEO from "../components/seo"
import {inject,observer} from 'mobx-react'
import {vBox,hBox} from "gx-web-ui/utils/flex";
import LoginModal from '../components/LoginModal'
import VerificationModal from '../components/VerificationModal'
import LandingPageSearchComponent from '../components/LandingPageSearchComponent'
import LandingPagePropertyTabPanel from '../components/LandingPagePropertyTabPanel'
import LandingPageOptions from '../components/LandingPageOptions'
import MePanel from '../components/setting/MePanel'
import LandingPageMePanel from '../components/setting/LandingPageMePanel'
import backGroundImage from '../assets/images/Cover-img.jpeg'
import MobileBackGroundImage from '../assets/images/Mobile-BG.png'
import GooglePlayDownloadBtn from '../components/common/GooglePlayDownloadBtn'
import AppStoreDownloadBtn from '../components/common/AppStoreDownloadBtn'
import useParentComponentDimension from '../utils/parentComponentDimension'
import LandingPageFilterSearch from '../components/LandingPageFilterSearch'

import loadGMaps from '../utils/loadGMaps'
import withRoot from '../withRoot'

import MobileIndexSearch from '../components/mobile/MobileIndexSearch';
import PropertySlider from '../components/mobile/PropertySlider';
import MobileIndexStore from '../components/mobile/MobileIndexStore';

const IndexPage = (props) => {
  const {appStore, uiStore} = props
  const {userLocation, setUserLocation} = appStore
  const {openLogin,setOpenLogin,isVerificationOpen,setIsVerificationOpen,isMeSettingOpen} = uiStore
  const [dimensions, setDimensions] = useState({ width:1440, height: 0 });
  const targetRef = useRef();
  
  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  useEffect(() => {
    if(_.isNull(userLocation)){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setUserLocation({
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude
          })
        });
      } else {
        setUserLocation({
          "latitude": -33.8688,
          "longitude": 151.2093
        })
      }
    }else{
      console.log(userLocation)
    }
  }, [userLocation])

  return <Container >
    <SEO title="Home" />
      <div style={{position:'relative'}}>
        <div ref={targetRef} style={{...vBox('center'),width : '100%'}}> 
          { isMobile? <MobileIndexSearch /> :
              <div  style={{...hBox('center','center'), backgroundImage : `url(${backGroundImage})`,backgroundRepeat : 'no-repeat',backgroundPosition:'center',backgroundSize: 'cover',width : "100%",height : 600}}>
                <LandingPageSearchComponent parentDivWidth={dimensions.width}/>
              </div>
          }
          {isMobile? <PropertySlider parentDivWidth={dimensions.width}/> : <LandingPagePropertyTabPanel  parentDivWidth={dimensions.width}/> }
          { isMobile? <MobileIndexStore /> : 
              <div style={{ ...vBox('start','center'),backgroundImage : `url(${MobileBackGroundImage})`,backgroundRepeat : 'no-repeat',backgroundPosition:'center',backgroundSize: 'cover',width : "100%",height : `calc(${dimensions.width}px / 2.41)`,paddingLeft : 180}}>
              </div>
          }
          <div style={{width : '100%', padding : '0px 0px 0px 0px'}}>
            <LandingPageOptions/>
          </div>
        </div>   
      </div>
    <LoginModal visible={openLogin} handleCancel={()=>setOpenLogin(false)}   />
    <VerificationModal visible={isVerificationOpen} handleCancel={()=>setIsVerificationOpen(false)} />
    <LandingPageFilterSearch />
  </Container>
}

export default compose(withRoot,inject('appStore','uiStore'),observer)(IndexPage)
