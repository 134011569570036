import React, { useEffect, useState } from "react";
import {inject,observer} from 'mobx-react';
import compose from 'recompose/compose';
import {navigate} from "gatsby";
import _ from 'lodash';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';

import {propertySlugGeneratorFromRaw} from '@src/utils/slug';
import ProjectCard from '../property/ProjectCard';
import PropertyCard from '../property/PropertyCard';
import Loading from '../common/Loading';

import './MobileLayout.less';

const PropertySlider = (props) => {
  const theme = useTheme();
  const {parentDivWidth, appStore, propertyStore, uiStore} = props
  const {setIsShowRecommendation, checkLogin } = uiStore
  const {getMapProperties_,  getLandPageProperties_, nearbyPropertyList, allPropertyList, collectionList, savedSearchList,  isBackgroundDataLoading, landingPageLoading,favorProperty_,
    unfavorProperty_,
    toggleFavorByIndexByType} = propertyStore;

  useEffect(() => {
    getLandPageProperties_()
  }, [appStore.loggedInUser])

  const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`};

  const onToggleFavor = (listingId,index, type)=>(newState)=>{
    if(newState===true){
      favorProperty_(listingId)
    }else{
      unfavorProperty_(listingId)
    }
    toggleFavorByIndexByType(newState,index,type)
    getLandPageProperties_()
  }

  return (
    <Loading loading={landingPageLoading}>
    {
      !_.isEmpty(allPropertyList)&&
        <div className="m-slider">
          <div className="m-slider-header">
            <div className="label">Featured</div>
            <div className="action" onClick={() => navigate('/exploreList')}>View All</div>
          </div>
          <div className="m-slider-content">
          { allPropertyList.map((data,index)=>
            <div item key={index} className="content-item" style={{width: parentDivWidth - 80, flexBasis: parentDivWidth - 80}}>
              {
                data.processedData.isProject ?
                <ProjectCard height={250} onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`} rootStyle={boxShadow} project={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId, index, 'newDevelopment'))}/>
                :
                <PropertyCard height={250}  onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`} rootStyle={boxShadow}  property={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId, index, 'newDevelopment'))}/>
              }
            </div>
          )}
          </div>
        </div>
    }
    {
      !_.isEmpty(nearbyPropertyList)&&
        <div className="m-slider">
          <div className="m-slider-header">
            <div className="label">Near By</div>
            <div className="action" onClick={() => navigate('/exploreList')}>View All</div>
          </div>
          <div className="m-slider-content">
          { nearbyPropertyList.map((data,index)=>
            <div item key={index} className="content-item" style={{width: parentDivWidth - 80, flexBasis: parentDivWidth - 80}}>
              {
                data.processedData.isProject ?
                <ProjectCard height={250} onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`} rootStyle={boxShadow} project={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId, index, 'newDevelopment'))}/>
                :
                <PropertyCard height={250}  onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`} rootStyle={boxShadow}  property={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId, index, 'newDevelopment'))}/>
              }
            </div>
          )}
          </div>
        </div>
    }
    </Loading>
  )
}

export default compose(inject('propertyStore','appStore','uiStore'), observer)(PropertySlider);
